import React from "react"
import Header from "../components/header"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { INLINES, BLOCKS } from "@contentful/rich-text-types"
import BackgroundImage from "gatsby-background-image"
import Footer from "../components/footer"
import styled from "styled-components"

const website_url = "https://youchangeearth.org"
const options = {
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      return (
        <a
          href={node.data.uri}
          target={`${
            node.data.uri.startsWith(website_url) ? "_self" : "_blank"
          }`}
          rel={`${
            node.data.uri.startsWith(website_url) ? "" : "noopener noreferrer"
          }`}
          style={{ textDecoration: "none", color: "var(--green)" }}
        >
          {node.content[0].value}
        </a>
      )
    },
  },
}

const MainContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    max-width: 980px;
    padding: 3rem;
    padding-top: 1rem;
  }
`

const DescriptionContainer = styled.div`
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    padding: 0 15%;
  }
`

const Communities = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "communities-header.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allContentfulCommunitiesPage(sort: { fields: orderTag }) {
        nodes {
          header
          listings {
            header
            link {
              link
            }
            description {
              json
            }
          }
          description {
            json
          }
        }
      }
    }
  `)
  return (
    <div>
      <SEO title="Communities" />

      <BackgroundImage fluid={data.placeholderImage.childImageSharp.fluid}>
        <Header textColor="white" />
        <h1
          style={{
            textAlign: "center",
            fontSize: "1.8rem",
            padding: "2rem",
            paddingTop: "10rem",
            color: "white",
          }}
        >
          <b>Communities</b>
        </h1>
      </BackgroundImage>
      <div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "2rem",
            marginTop: "3rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <DescriptionContainer>
            <p>
              We have ten years to halve the emissions in our communities. Join
              the thousands of caring citizens who are engaging in communities
              fit for them.
              <br /> <br />
              We have crowd-sourced and curated the list below. Explore and join
              some now to stay up to date and use your skills, time, or
              interests for our futures.
            </p>
          </DescriptionContainer>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <MainContainer>
            {data.allContentfulCommunitiesPage.nodes.map(item => {
              return (
                <>
                  <div style={{ padding: "1rem" }}>
                    <h1 style={{ fontWeight: 600, fontSize: "1.4rem" }}>
                      {item.header}
                    </h1>
                    {item.description && (
                      <p style={{ fontSize: "0.8rem", marginTop: "1rem" }}>
                        {documentToReactComponents(
                          item.description.json,
                          options
                        )}
                      </p>
                    )}
                  </div>
                  <div style={{ padding: "1rem" }}>
                    {item.listings.map(listing => {
                      return (
                        <>
                          <a
                            href={listing.link.link}
                            target={`${
                              listing.link.link.startsWith(website_url)
                                ? "_self"
                                : "_blank"
                            }`}
                            rel={`${
                              listing.link.link.startsWith(website_url)
                                ? ""
                                : "noopener noreferrer"
                            }`}
                            style={{
                              textDecoration: "none",
                              color: "var(--green)",
                            }}
                          >
                            <h4
                              style={{
                                color: "var(--green)",
                                marginBottom: "0.3rem",
                              }}
                            >
                              {listing.header}
                            </h4>
                          </a>
                          {listing.description && (
                            <p style={{ marginBottom: "2rem" }}>
                              {documentToReactComponents(
                                listing.description.json,
                                options
                              )}
                            </p>
                          )}
                          {!listing.description && <br />}
                        </>
                      )
                    })}
                  </div>
                </>
              )
            })}
          </MainContainer>
        </div>
        <div
          style={{
            textAlign: "center",
            margin: "2rem",
            marginTop: "-1rem",
            marginBottom: "4rem",
          }}
        >
          <p>
            We're always looking for ways to expand and improve our list of
            climate communities. If you know of a community that we may have
            missed while curating our list,{" "}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdXL85CT5lR_vGTQnabF6ffkMy7I0VIkOE0XRQ8Lya5xbEKpw/viewform?usp=sf_link"
              rel="noopener noreferrer"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "var(--green)",
              }}
            >
              please let us know on this form!
            </a>
          </p>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Communities
